import React from "react"

import { withArticleListing } from "./withArticleListing"
import { ArticleCard } from "./ArticleCard"

import { getFluidGatsbyImage, routeResolver } from "../Wrappers/Helpers"
import { Grid } from "../Grid/Grid"
import { Container, Column, Title } from "./ArticleListing.styled"

export const ArticleListing = withArticleListing(
  ({ title, articles }: Props): JSX.Element => {
    return (
      <>
        <Container topSpacing={`md`} width={`xl`}>
          <Title>{title}</Title>
        </Container>

        {articles?.length > 0 && (
          <Container spacing={`lg`} condense={`<md`} width={`xl`}>
            <Grid>
              {articles?.map(item => (
                <Column key={item?.id} width={`md:w-1/2 lg:w-1/3`}>
                  <ArticleCard
                    size={`full`}
                    image={item.image && getFluidGatsbyImage(item.image, { maxWidth: 800 })}
                    title={item.title}
                    content={item.summary}
                    link={routeResolver({ item })}
                    date={`${item.attributes?.publishedAt && new Date(item.attributes.publishedAt)}`}
                  />
                </Column>
              ))}
            </Grid>
          </Container>
        )}
      </>
    )
  }
)

export interface Props {
  title: string
  articles: Array<{
    id: string
    image: any
    title: string
    summary: string
    attributes: {
      publishedAt: Date
    }
  }>
}
