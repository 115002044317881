import React, { useMemo } from "react"

import { useCore } from "../../hooks/useCore"

export const withArticleListing = Component =>
  React.memo(({ name = "ArticleListing", page, articles, ...props }) => {
    const {
      helpers: { sanityContent },
    } = useCore()

    const title = page?.title
    const content = sanityContent(page?.content)

    const items = articles?.edges?.map(({ node }) => node)

    Component.displayName = name
    return useMemo(() => <Component {...props} {...page} title={title} content={content} articles={items} />, [])
  })
