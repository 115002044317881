import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { H4, H5, P } from "../../styled/Text"
import { StyledCard } from "../../styled/Card"

export const Card = tw(StyledCard)`p-5`
export const Frame = tw.figcaption``
export const Image = tw(GatsbyImage)`bg-grey-lightest inline-block w-full mb-3 rounded-lg h-43`
export const Title = tw(H4)`text-grey-dark mt-3 mb-2 lg:text-md text-md`
export const DateTime = tw.time`block font-bold text-xxs leading-none uppercase tracking-wide text-grey-light mt-3`
export const Subtitle = tw(H5)`block font-bold lg:text-xxs text-xxs text-green leading-none uppercase mb-0`
export const Content = tw(P)`text-grey-mid mb-5 lg:text-sm text-sm`
export const CardLink = styled(Link)`
  ${({ size }) => (size === "full" ? tw`block` : tw`inline-block`)}
`
export const MoreLink = tw(Link)`font-bold lg:text-xs text-xs text-grey-mid mb-2 leading-none uppercase`
