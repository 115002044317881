import React from "react"

import { Card, Frame, Image, Title, DateTime, Subtitle, Content, CardLink, MoreLink } from "./ArticleCard.styled"

export const ArticleCard = ({ link, image, subtitle, date, title, content, ...props }: Props): JSX.Element => (
  <CardLink to={link} {...props}>
    <Card {...props} shadow={`medium`} hover>
      {image && <Image image={image} alt={title || ""} />}
      <Frame {...props}>
        <Subtitle>{subtitle}</Subtitle>
        {date ? (
          <DateTime>
            {new Intl.DateTimeFormat("en-AU", {
              day: "2-digit",
              month: "long",
              year: "numeric",
              timeZone: "Australia/Melbourne",
            }).format(Date.parse(date))}
          </DateTime>
        ) : null}
        <Title {...props}>{title}</Title>
        <Content {...props}>{content}</Content>
        <MoreLink to={link}>Read More</MoreLink>
      </Frame>
    </Card>
  </CardLink>
)

export interface Props {
  title?: string
  link?: string
  subtitle?: string
  content?: string
  date?: string
  image?: any
  size?: string
}
