import React from "react"
import { graphql } from "gatsby"

import { ArticleListing as Page } from "../components/Article/ArticleListing"

export const query = graphql`
  query {
    page: sanityPageArticles {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
    articles: allSanityArticle(filter: { attributes: { publishedAt: { ne: null } } }, sort: { fields: attributes___publishedAt, order: DESC }) {
      edges {
        node {
          ...GatsbyArticleFragment
        }
      }
    }
  }
`

export default ({ data, ...props }): JSX.Element => <Page {...props} {...data} />
