import { useApp } from "../../hooks/useApp"
import { useImage } from "../../hooks/useImage"

const screens = {
  small: `<md`,
  large: `md>`,
}

export const getFluidGatsbyImage = (image, options = {}) => {
  const { getGatsbyImageData } = useImage()
  return getGatsbyImageData(image, options)
}

export const linkResolver = item => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const document = item?.document || item

  if (!document) return {}

  const type = document._type || document.__typename || document.type
  if (type === `navigationSub`) return { url: null, type: `navigationSub` }
  if (type === `link`) return { url: document?.link, external: document?.external }

  const itemType = Object.keys(routes)
    .find(key => type?.toUpperCase().replace("PAGEACCOUNT", "")?.includes(key))
    ?.toLowerCase()

  return {
    title: item.title || document.title || null,
    type: itemType ? itemType.toLowerCase() : null,
    url: routeResolver({ item: document[itemType] || document }),
  }
}

export const routeResolver = ({ item, type = false }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const itemType = type || item?.type || item?._type
  const handle =
    item?.slug?.current || item?.shopify?.shopifyHandle || item?.handle || item?._id?.replace("drafts.", "").replace("page", "").toLowerCase()
  const route = routes[itemType?.toUpperCase()] || routes[itemType?.toUpperCase().replace("PAGEACCOUNT", "")] || routes[`PAGE`]

  return `${route}${handle && (Object.keys(routes).includes(itemType?.toUpperCase()) || !route) ? `/${handle}` : ``}`
}

export const transformColour = colour => colour?.toLowerCase().replace(`light`, `white`).replace(`dark`, `black`).replace(/ /g, ``)
export const transformResponsive = responsive => ({
  ...responsive,
  show: screens[responsive?.show?.toLowerCase().replace(/ /g, ``).replace(`screens`, ``)] || null,
})
export const transformSize = size => size?.toLowerCase().replace(/ /g, ``).replace(`extra`, `x`)
